import "core-js/modules/es.array.at.js";
import "core-js/modules/es.string.at-alternative.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from "vuex";
import { alertTriangleIcon, checkCircleIcon } from "@debionetwork/ui-icons";
import { addMenstrualCalendar, addMenstrualCycleLog, updateMenstrualCalendar, updateMenstrualCycleLog } from "@/common/lib/polkadot-provider/command/menstrual-calendar";
import { getLastMenstrualCalendarByOwner, getLastMenstrualCalendarCycleLogByOwner, getMenstrualCycleLog, getMenstrualCalendarById } from "@/common/lib/polkadot-provider/query/menstrual-calendar";
import Calendar from "@/common/components/Calendar";
import DaySelectAverage from "@/common/components/DaySelectAverage";
import MenstrualCalendarBanner from "./Banner";
export default {
  name: "SelectMenstrualCalendar",
  components: {
    Calendar,
    DaySelectAverage,
    MenstrualCalendarBanner
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    alertTriangleIcon,
    checkCircleIcon,
    monthList: [{
      value: 0,
      text: "January"
    }, {
      value: 1,
      text: "February"
    }, {
      value: 2,
      text: "March"
    }, {
      value: 3,
      text: "April"
    }, {
      value: 4,
      text: "May"
    }, {
      value: 5,
      text: "June"
    }, {
      value: 6,
      text: "July"
    }, {
      value: 7,
      text: "August"
    }, {
      value: 8,
      text: "September"
    }, {
      value: 9,
      text: "October"
    }, {
      value: 10,
      text: "November"
    }, {
      value: 11,
      text: "December"
    }],
    selectedMonthText: "",
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    selectedDates: null,
    submitEnabled: false,
    submitPreview: false,
    selectAverage: true,
    showAlertUnsaved: false,
    showAlertCancel: false,
    showAlert: false,
    isSuccess: false,
    stepText: "",
    routeStateSave: null,
    nextStatus: false,
    daySelectedAverage: 21,
    startDaySelectedAverage: 21,
    menstrualCalendarId: null,
    menstrualCalendarCycleLogIds: null,
    menstrualCalendarCycleLogDetails: null,
    lastMenstrualCalendarCycle: null,
    menstrualCalendarCycleDetail: null,
    menstrualCalendarDetail: null,
    dateSelected: []
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      pair: state => state.substrate.wallet
    })
  },
  beforeRouteLeave(to, _, next) {
    if (this.isUpdate && !this.routeStateSave) {
      this.showAlertUnsaved = true;
      this.routeStateSave = to;
    }
    next(this.nextStatus);
  },
  watch: {
    async selectedMonthText(newMonth) {
      var _this$menstrualCalend;
      this.submitPreview = true;
      this.selectedMonth = this.monthList.find(value => value.text === newMonth).value;
      if ((_this$menstrualCalend = this.menstrualCalendarId) !== null && _this$menstrualCalend !== void 0 && _this$menstrualCalend.length) this.isUpdate = true;
      if (this.isUpdate) await this.fetchData();
      this.submitPreview = false;
    },
    selectedDates(newSelected) {
      if (this.dateSelected.find(date => date.getTime() === newSelected.getTime())) {
        this.dateSelected = this.dateSelected.filter(date => date.getTime() !== newSelected.getTime());
        return;
      }
      this.dateSelected.push(newSelected);
      this.submitEnabled = this.dateSelected.length > 0;
      return;
    }
  },
  async created() {
    this.stepText = this.isUpdate ? "By choosing your average cycle day, you’ll get your menstrual calendar information" : "Help us get to know you more deeply, complete these steps now!";
    document.querySelector("html").style.overflowY = null;
    const today = new Date();
    this.selectedMonthText = this.monthList[today.getMonth()].text;
    this.currentYear = today.getFullYear().toString();
  },
  async beforeMount() {
    var _this$menstrualCalend2;
    this.menstrualCalendarId = await getLastMenstrualCalendarByOwner(this.api, this.pair.address);
    if ((_this$menstrualCalend2 = this.menstrualCalendarId) !== null && _this$menstrualCalend2 !== void 0 && _this$menstrualCalend2.length) this.isUpdate = true;
    if (this.isUpdate) await this.fetchData();
  },
  methods: {
    ...mapMutations({
      setIsStart: "menstrualCalendar/SET_ISSTART"
    }),
    prev() {
      this.submitPreview = true;
      if (this.selectedMonth > 0) {
        this.selectedMonth--;
      } else {
        this.selectedMonth = 11;
        this.selectedYear--;
      }
      this.selectedMonthText = this.monthList[this.selectedMonth].text;
      this.submitPreview = false;
    },
    next() {
      this.submitPreview = true;
      if (this.selectedMonth < 11) {
        this.selectedMonth++;
      } else {
        this.selectedMonth = 0;
        this.selectedYear++;
      }
      this.selectedMonthText = this.monthList[this.selectedMonth].text;
      this.submitPreview = false;
    },
    async fetchData() {
      this.submitPreview = true;
      this.menstrualCalendarCycleLogIds = await getLastMenstrualCalendarCycleLogByOwner(this.api, this.menstrualCalendarId.at(-1));
      const cycle = [];
      for (let i = 0; i < this.menstrualCalendarCycleLogIds.length; i++) {
        const id = this.menstrualCalendarCycleLogIds[i];
        const detail = await getMenstrualCycleLog(this.api, id);
        cycle.push(detail);
      }
      const menstrualCalendarDetail = await getMenstrualCalendarById(this.api, this.menstrualCalendarId.at(-1));
      const firstDateCurrentMonth = new Date(this.selectedYear, this.selectedMonth, 1);
      const firstDateNextMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0);
      const dayFirstDateCurrentMonth = firstDateCurrentMonth.getDay() === 0 ? 6 : firstDateCurrentMonth.getDay() - 1;
      const dayFirstDateNextMonth = firstDateNextMonth.getDay() === 0 ? 6 : firstDateNextMonth.getDay() - 1;
      const startDate = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1));
      const endDate = new Date(this.selectedYear, this.selectedMonth + 1, 6 - dayFirstDateNextMonth);
      const menstrualCalendarData = {
        addressId: menstrualCalendarDetail.addressId,
        averageCycle: menstrualCalendarDetail.averageCycle,
        cycleLog: [],
        isUpdate: true
      };
      this.menstrualCalendarCycleLogDetails = cycle;
      let date = startDate;
      let indexDate = 0;
      while (date.getTime() < endDate.getTime()) {
        date = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1) + indexDate);
        const log = cycle.filter(log => Number(log.date.replaceAll(",", "")) === date.getTime());
        const menstruation = log[0];
        const currentData = {
          date: date.getTime(),
          menstruation: log.length && menstruation.menstruation ? 1 : 0,
          symptoms: []
        };
        menstrualCalendarData.cycleLog.push(currentData);
        indexDate++;
      }
      this.menstrualCalendarCycleDetail = menstrualCalendarData;
      this.startDaySelectedAverage = Number(menstrualCalendarDetail.averageCycle);
      this.daySelectedAverage = this.startDaySelectedAverage;
      this.submitPreview = false;
    },
    async onSubmit() {
      const menstrualCalendarInfo = [];
      this.dateSelected.forEach(d => {
        menstrualCalendarInfo.push({
          date: d.getTime(),
          symptoms: [],
          menstruation: true
        });
      });
      if (this.isUpdate) {
        this.showAlert = true;
      } else {
        this.submitPreview = true;
        this.nextStatus = true;
        await addMenstrualCalendar(this.api, this.pair, this.daySelectedAverage, async () => {
          const idMenstrualCalendar = (await getLastMenstrualCalendarByOwner(this.api, this.pair.address)).at(-1);
          await addMenstrualCycleLog(this.api, this.pair, idMenstrualCalendar, menstrualCalendarInfo, () => {
            this.$router.push({
              name: "menstrual-calendar-detail"
            });
          });
          this.setIsStart(true);
        });
      }
    },
    backButton() {
      this.selectAverage = true;
    },
    async onSubmitAverage() {
      this.startDaySelectedAverage = this.daySelectedAverage;
      this.selectAverage = false;
      this.stepText = "By choosing your menstrual dates, we will estimate your menstruation date";
    },
    goToDetailMenstrual() {
      this.nextStatus = true;
      this.$router.push({
        name: "menstrual-calendar-detail"
      });
    },
    async toUpdateMenstrual() {
      this.showAlert = false;
      this.submitPreview = true;
      await updateMenstrualCalendar(this.api, this.pair, this.menstrualCalendarId.at(-1), this.daySelectedAverage, async () => {
        await this.toUpdateMenstrualCycleLog();
      });
    },
    async toUpdateMenstrualCycleLog() {
      const dateList = this.dateSelected.map(date => date.getTime());
      const mensAdded = [];
      const newDate = [];
      dateList.forEach(date => {
        const data = this.menstrualCalendarCycleLogDetails.find(detail => Number(detail.date.replaceAll(",", "")) === date);
        if (data) {
          mensAdded.push(data);
        } else {
          newDate.push(date);
        }
      });
      if (newDate.length) {
        const menstrualCalendarInfo = [];
        this.dateSelected.forEach(detail => {
          if (newDate.find(date => date === detail.getTime())) {
            menstrualCalendarInfo.push({
              date: detail.getTime(),
              symptoms: [],
              menstruation: true
            });
          }
        });
        await addMenstrualCycleLog(this.api, this.pair, this.menstrualCalendarId.at(-1), menstrualCalendarInfo, async () => {
          if (!mensAdded.length) {
            this.isSuccess = true;
            this.submitPreview = false;
          }
        });
      }
      if (mensAdded.length) {
        const menstUpdateData = [];
        mensAdded.forEach(async data => {
          menstUpdateData.push({
            id: data.id,
            menstrualCalendarId: data.menstrualCalendarId,
            date: data.date.replaceAll(",", ""),
            menstruation: data.menstruation ? false : true,
            symptoms: data.symptoms
          });
        });
        await updateMenstrualCycleLog(this.api, this.pair, menstUpdateData, () => {
          this.isSuccess = true;
          this.submitPreview = false;
          return;
        });
      }
    },
    async updateMenstrualSuccess() {
      this.isSuccess = false;
      this.submitPreview = true;
      this.nextStatus = true;
      this.$router.push({
        name: "menstrual-calendar-detail"
      });
    },
    goToNextRoute() {
      this.showAlertUnsaved = false;
      if (this.isUpdate) {
        this.nextStatus = true;
        this.routeStateSave && this.$router.push({
          name: this.routeStateSave.name
        });
      }
    },
    cancelUnsaved() {
      this.routeStateSave = null;
      this.showAlertUnsaved = false;
      this.$router.push({
        name: "menstrual-calendar-detail"
      });
    }
  }
};